import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';

const Chilou = ({ image }) => {
  return (
    <div className="column is-paddingless is-marginless">
      <BackgroundImage fluid={image} style={{ height: '105vh' }}>
        <div className="columns is-paddingless is-marginless">
          <div className="column is-3 chilouInfo">
            <p className="heading">Chilou</p>
            <p>
              Erat aliquyam sadipscing et ipsum clita skasd erat diam, dolores amet elitr voluptua
              eirmod nonumy, eos diam elitr sadipscing et diam magna ea. Sea et consetetur aliquyam
              sit magna sadipscing, dolores dolor lorem et sea et aliquyam erat, ea lorem justo
              ipsum takimata. Kasd sed eos gubergren diam diam aliquyam.
            </p>
          </div>
        </div>
      </BackgroundImage>
    </div>
  );
};

Chilou.propTypes = {
  image: PropTypes.object.isRequired
};

export default Chilou;
