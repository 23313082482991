import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';

const HalfProductDisplay = ({ image }) => {
  return (
    <div className="column is-half is-paddingless is-marginless">
      <BackgroundImage fluid={image} style={{ height: '102vh' }} />
    </div>
  );
};

HalfProductDisplay.propTypes = {
  image: PropTypes.object.isRequired
};

export default HalfProductDisplay;
