import React, { useState, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import config from 'react-reveal/globals';
import Shoefilou from '../components/Shoefilou';
import Chilou from '../components/Chilou';
import HalfProductDisplay from '../components/HalfProductDisplay';

import '../scss/style.scss';

config({ ssrFadeout: true });

const IndexPage = () => {
  const [hoverLinks, setHoverLinks] = useState(false);
  const [hoverRechts, setHoverRechts] = useState(false);

  useEffect(() => {
    const listener = e => {
      if (e.clientX < window.innerWidth / 2 - (window.innerWidth / 100) * 10) {
        setHoverLinks(true);
      } else {
        setHoverLinks(false);
      }

      if (e.clientX > window.innerWidth / 2 + (window.innerWidth / 100) * 10) {
        setHoverRechts(true);
      } else {
        setHoverRechts(false);
      }
    };
    window.addEventListener('mousemove', listener);
    return () => {
      window.removeEventListener('mousemove', listener);
    };
  }, []);
  return (
    <StaticQuery
      query={graphql`
        query {
          shoefilu: file(relativePath: { eq: "Seitenansicht-Holz.png" }) {
            childImageSharp {
              fluid(maxWidth: 1080, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          bgshoe: file(relativePath: { eq: "bg.png" }) {
            childImageSharp {
              fluid(maxWidth: 1080, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          chilou: file(relativePath: { eq: "02.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 1080, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          logo: file(relativePath: { eq: "logo-circle.png" }) {
            childImageSharp {
              fluid(maxWidth: 1080, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      `}
      render={data => (
        <div className="hero is-fullheight">
          <div className="container is-marginless is-paddingless">
            <Fade when={!hoverLinks && !hoverRechts}>
              <div className="logoGroup">
                <div className="columns is-overlay is-vcentered">
                  <div className="column is-2 is-offset-5 logo">
                    <Image fluid={data.logo.childImageSharp.fluid} />
                  </div>
                </div>
                <div className="columns">
                  <HalfProductDisplay image={data.chilou.childImageSharp.fluid} />
                  <HalfProductDisplay image={data.bgshoe.childImageSharp.fluid} />
                </div>
                <div className="black-opacity" />
              </div>
            </Fade>
            <div className="columns">
              <Slide left when={hoverLinks}>
                <div className={`${hoverLinks ? 'width' : 'nowidth'}`}>
                  <Chilou image={data.chilou.childImageSharp.fluid} />
                </div>
              </Slide>
              <Slide right when={hoverRechts}>
                <div className={`${hoverRechts ? 'width' : 'nowidth'}`}>
                  <Shoefilou image={data.bgshoe.childImageSharp.fluid} />
                </div>
              </Slide>
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default IndexPage;
